import { IHttpQueryFilter } from "src/api/Interfaces"

import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useForm, { IForm } from "src/hooks/useForm";
import { useTranslation } from "react-i18next";
import classNames from "src/components/Utilities/classNames";
import Button from "src/components/Actions/Button";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";


export interface FiltersFormProps<T> {
  filters: IHttpQueryFilter[];
  setFilters: (filters: IHttpQueryFilter[]) => void;
  form: IForm<T>;
  formRender: (form: IForm<T>) => React.ReactNode;
  prepareFilters: (form: IForm<T>) => IHttpQueryFilter[];
}

export interface TableFiltersProps<T> {
  filters: IHttpQueryFilter[];
  setFilters: (filters: IHttpQueryFilter[]) => void;
  formRender: (form: IForm<T>) => React.ReactNode;
  prepareFilters: (form: IForm<T>) => IHttpQueryFilter[];
}

export function UserFiltersForm<T>(props: FiltersFormProps<T>) {
  const { setFilters, form, formRender, prepareFilters } = props;
  const timerRef = useRef<NodeJS.Timeout>();

  const setChangedFilters = () => {
    setFilters(prepareFilters(form));
  }

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(setChangedFilters, 500);
  }, [form.data]);

  return formRender(form);
}

export default function TableFilters<T>(props: TableFiltersProps<T>) {
  const {
    filters, setFilters,
    formRender, prepareFilters,
  } = props;

  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const form = useForm<T>();

  return (
    <>
      <Button colorName="primary" onClick={() => setOpen(true)} className="px-5 py-3">
        <FontAwesomeIcon icon={faFilter} className="w-5" />
        {t('common.filters.filters')}
        {filters.length > 0 && <span className="bg-white rounded-md text-[--color-primary-600] ml-1 pl-1.5 pr-2">{filters.length}</span>}
      </Button >
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className={classNames([isOpen ? "block" : "none", "relative z-50"])} onClose={setOpen}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-full max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-medium leading-6 text-gray-900">
                            {t('common.filters.filters')}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[--color-primary-500] focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">{t('common.actions.close')}</span>
                              <FontAwesomeIcon icon={faTimes} className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <UserFiltersForm<T> filters={filters} setFilters={setFilters} form={form} formRender={formRender} prepareFilters={prepareFilters} />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}