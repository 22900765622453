import { faChevronLeft, faChevronRight } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Button from "src/components/Actions/Button";

interface BookTrainingHeaderProps {
  onClickBack?: () => void;
  onClickNext?: () => void;
  submitButton?: boolean;
  disabledBack?: boolean;
  disabledNext?: boolean;
  children?: React.ReactNode;
}

const BookTrainingHeader = (props: BookTrainingHeaderProps) => {
  const { children, onClickBack, onClickNext, disabledBack, disabledNext, submitButton } = props;
  const { t } = useTranslation();
  return (
    <div className="sm:flex justify-between mb-8 lg:mb-0">
      <div>
        {children && <h2 className="text-2xl">{children}</h2>}
      </div>
      <div className="flex gap-x-3">
        {onClickBack && <Button type="button" colorName="gray" className="px-5 py-3 border-[--color-primary-900] w-full sm:w-auto mt-4 sm:mt-0 flex-1" disabled={disabledBack} onClick={onClickBack}>
          <FontAwesomeIcon icon={faChevronLeft} className="inline h-4" />
        </Button>}
        {onClickNext && <Button type="button" colorName="primary" className="px-5 py-3 border-[--color-primary-900] w-full sm:w-auto mt-4 sm:mt-0 flex-grow sm:flex-grow-0" disabled={disabledNext} onClick={onClickNext}>
          {t('ui.steps.nextStep')}
          <FontAwesomeIcon icon={faChevronRight} className="inline h-4 ml-2" />
        </Button>}
        {submitButton && <Button type="submit" colorName="primary" className="px-5 py-3 border-[--color-primary-900] w-full sm:w-auto mt-4 sm:mt-0 flex-grow sm:flex-grow-0" disabled={disabledNext}>
          {t('ui.steps.nextStep')}
          <FontAwesomeIcon icon={faChevronRight} className="inline h-4 ml-2" />
        </Button>}
      </div>
    </div>
  )
}

export default BookTrainingHeader;