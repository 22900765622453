import { IInt32WeeklyHeatMapStatistics } from "src/api/stable/Booking";

export interface WeeklyHeatMapResult {
  values: number[][];
  legendY: string[];
}

export const weeklyHeatMapResult = (
  data?: IInt32WeeklyHeatMapStatistics,
  legend?: string[]
): WeeklyHeatMapResult => {
  if (!data) return { values: [], legendY: [] };
  const defaultLegend = (legend
    ? Array.from(new Set([...legend, ...Object.keys(data.values || {})]))
    : Array.from(new Set(Object.keys(data.values || {}))));
  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const values = defaultLegend.map((time) => {
    return daysOfWeek.map((day) => {
      return data.values?.[time]?.[day] || 0;
    });
  });

  return {
    values,
    legendY: defaultLegend,
  };
};