export const growColorText = (currentValue?: number, previousValue?: number) => {
  if ((currentValue ?? 0) > (previousValue ?? 0)) return 'text-emerald-700';
  if ((currentValue ?? 0) < (previousValue ?? 0)) return 'text-red-700';
  return 'text-slate-700';
};

export const growColorBackground = (currentValue?: number, previousValue?: number) => {
  if ((currentValue ?? 0) > (previousValue ?? 0)) return 'bg-emerald-700';
  if ((currentValue ?? 0) < (previousValue ?? 0)) return 'bg-red-700';
  return 'bg-slate-700';
};

export const growColorBorder = (currentValue?: number, previousValue?: number) => {
  if ((currentValue ?? 0) > (previousValue ?? 0)) return 'border-emerald-700';
  if ((currentValue ?? 0) < (previousValue ?? 0)) return 'border-red-700';
  return 'border-slate-700';
};

export const growColor = (currentValue?: number, previousValue?: number) => {
  if ((currentValue ?? 0) > (previousValue ?? 0)) return 'emerald';
  if ((currentValue ?? 0) < (previousValue ?? 0)) return 'red';
  return 'slate';
};