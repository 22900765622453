import { faBrowser, faCode, faCogs, faDesktop, faFlaskVial, faIdBadge, faMobile, faQuestion, faRotateReverse, faTimeline } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export interface DataSourceComponentProps {
  value?: number;
}

export const DataSourceLabel = (props: DataSourceComponentProps) => {
  const { value } = props;
  const { t } = useTranslation();
  return (
    <span>{t(`common.dataSource.${value ?? 12}`)}</span>
  );
}

export const getDataSourceIcon = (value?: number) => {
  switch (value) {
    case 0:
      return faBrowser;
    case 1:
      return faMobile;
    case 2:
      return faDesktop;
    case 3:
      return faIdBadge;
    case 4:
    case 5:
      return faCode;
    case 6:
    case 7:
      return faRotateReverse;
    case 8:
    case 9:
      return faCogs;
    case 10:
      return faTimeline;
    case 11:
      return faFlaskVial;
    default:
      return faQuestion;
  }
}

export const DataSourceIcon = (props: DataSourceComponentProps) => {
  const { value } = props;
  const { t } = useTranslation();
  return (
    <span title={t(`common.dataSource.${value ?? 12}`)}><FontAwesomeIcon icon={getDataSourceIcon(value)} /></span>
  );
}