import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PieChart, Pie, Cell, Tooltip, Legend, LineChart, CartesianGrid, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { HttpQueryFilter, IHttpQueryFilter, Int32WeeklyHeatMapStatistics, TrainingsStatisticsClient, TrainingStatistics } from "src/api/stable/Booking";
import { Horse, HorsesClient, HorseTranslation, Instructor, InstructorsClient, InstructorTranslation, TrainingType, TrainingTypesClient, TrainingTypeTranslation } from "src/api/stable/Stable";
import GrowIcon from "src/components/Statistics/GrowIcon";
import GrowTag from "src/components/Statistics/GrowTag";
import StatisticsPeriodSwitch, { StatisticsPeriod } from "src/components/Statistics/StatisticsPeriodSwitch";
import WeekHeatmap from "src/components/Statistics/WeekHeatmap";
import { weeklyHeatMapResult } from "src/components/Statistics/weeklyHeatMapResult";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useConfigurationState from "src/hooks/useConfigurationState";
import useEntityTranslation from "src/hooks/useEntityTranslation";

export interface TrainingStatisticsProps {
  filters?: IHttpQueryFilter[];
}

export default (props: TrainingStatisticsProps) => {
  const { filters } = props;
  const { t } = useTranslation();
  const [statisticsPeriod, setStatisticsPeriod] = useState<StatisticsPeriod>(StatisticsPeriod.Week);

  const [response, setResponse] = useState<TrainingStatistics>();
  const [heatMapResponse, setHeatMapResponse] = useState<Int32WeeklyHeatMapStatistics>();
  const [types, setTypes] = useState<TrainingType[]>();
  const [horses, setHorses] = useState<Horse[]>();
  const [instructors, setInstructors] = useState<Instructor[]>();

  const typeTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const horseTranslation = useEntityTranslation<Horse, HorseTranslation>();
  const instructorTranslation = useEntityTranslation<Instructor, InstructorTranslation>();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new TrainingsStatisticsClient(apiConfiguration);
  const apiTypesClient = new TrainingTypesClient(apiConfiguration);
  const apiHorsesClient = new HorsesClient(apiConfiguration);
  const apiInstructorsClient = new InstructorsClient(apiConfiguration);

  const { legendY, values } = weeklyHeatMapResult(heatMapResponse, [
    '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00',
    '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'
  ])

  useEffect(() => {
    fetchTypes();
    fetchHorses();
    fetchInstructors();
  }, []);
  useEffect(() => {
    setHeatMapResponse(undefined);
    fetchHeatMaps();
  }, [statisticsPeriod, filters]);
  useEffect(() => {
    setResponse(undefined);
    fetch();
  }, [filters]);

  const fetch = () => {
    apiClient
      .statistics([...filters ?? []] as HttpQueryFilter[])
      .then(r => setResponse(r))
      .catch(console.error);
  }

  const fetchTypes = () => {
    apiTypesClient
      .get(undefined, undefined, 1000, undefined, undefined, undefined)
      .then(r => setTypes(r.items))
      .catch(console.error);
  }

  const fetchHorses = () => {
    apiHorsesClient
      .get(undefined, undefined, 1000, undefined, undefined, undefined)
      .then(r => setHorses(r.items))
      .catch(console.error);
  }

  const fetchInstructors = () => {
    apiInstructorsClient
      .get(undefined, undefined, 1000, undefined, undefined, undefined)
      .then(r => setInstructors(r.items))
      .catch(console.error);
  }

  const fetchHeatMaps = () => {
    apiClient
      .heatMapTrainings(statisticsPeriod, [...filters ?? []] as HttpQueryFilter[])
      .then(r => setHeatMapResponse(r))
      .catch(console.error);
  }

  return (
    <div>
      <h2 className="text-gray-700 text-lg">{(filters?.length ?? 0) > 0 ? t('common.filtered') : t('common.all')}</h2>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-5">
        <div className="py-5 border-b">
          <h3 className="text-xl">
            {t('common.period.thisWeek')}
            {response && <GrowTag currentValue={_.sum(response.thisWeek)} previousValue={_.sum(response.lastWeek)} percentage />}
          </h3>
          <h4 className="text-gray-500 text-sm h-16">{t('common.statistics.weeklyDescribed')}</h4>
          {response && <TrainingsLineChartWeek currentData={response.thisWeek!} lastData={response.lastWeek!} />}
        </div>
        <div className="py-5 border-b col-span-2">
          <h3 className="text-xl">
            {t('common.period.thisMonth')}
            {response && <GrowTag currentValue={_.sum(response.thisMonth)} previousValue={_.sum(response.lastMonth)} percentage />}
          </h3>
          <h4 className="text-gray-500 text-sm h-16">{t('common.statistics.monthlyDescribed')}</h4>
          {response && <TrainingsLineChartMonth currentData={response.thisMonth!} lastData={response.lastMonth!} />}
        </div>
        <div className="py-5 border-b col-span-2">
          <h3 className={`text-xl pb-1`}>
            {t('common.statistics.weekByWeek')}
            {response && <GrowIcon
              currentValue={response.weekByWeek![moment().get('isoWeek') - 1]}
              previousValue={response.weekByWeekLastYear![moment().get('isoWeek') - 1]}
            />}
          </h3>
          <h4 className="text-gray-500 text-sm h-16">{t('common.statistics.weekByWeekDescribed')}</h4>
          {response && <TrainingsLineChartWeekByWeek currentData={response.weekByWeek!} lastData={response.weekByWeekLastYear!} />}
        </div>
        <div className="py-5 border-b">
          <h3 className={`text-xl pb-1`}>
            {t('common.statistics.monthByMonth')}
            {response && <GrowIcon
              currentValue={response.monthByMonth![moment().get('month') - 1]}
              previousValue={response.monthByMonthLastYear![moment().get('month') - 1]}
            />}
          </h3>
          <h4 className="text-gray-500 text-sm h-16">{t('common.statistics.monthByMonthDescribed')}</h4>
          {response && <TrainingsLineChartMonthByMonth currentData={response.monthByMonth!} lastData={response.monthByMonthLastYear!} />}
        </div>
      </div>
      <div className="my-5 py-5 border-b">
        <div className="flex justify-between">
          <h3 className="text-xl">{t('stable.trainings.statistics.weeklyHeatmap')}</h3>
          <StatisticsPeriodSwitch period={statisticsPeriod} setPeriod={setStatisticsPeriod} />
        </div>
        <WeekHeatmap labelY={t('common.time')} legendY={legendY} values={values} />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <div className="py-5 border-b">
          <h3 className="text-xl">{t('stable.trainings.statistics.trainingTypes')}</h3>
          <h4 className="text-gray-500 text-sm">{t('common.fromBeginning')}</h4>
          {response && <TrainingsPieChart
            values={_.map(response?.types, (v, k) => ({ id: k, value: v }))}
            titles={types?.map(t => ({ id: t.id!, label: typeTranslation.getCurrentTranslation(t)?.name ?? t.id! }))}
          />}
          {!response && <div>{t('common.noData')}</div>}
        </div>
        <div className="py-5 border-b">
          <h3 className="text-xl">{t('stable.trainings.statistics.horses')}</h3>
          <h4 className="text-gray-500 text-sm">{t('common.fromBeginning')}</h4>
          {response && <TrainingsPieChart
            values={_.map(response?.horses, (v, k) => ({ id: k, value: v }))}
            titles={horses?.map(h => ({ id: h.id!, label: horseTranslation.getCurrentTranslation(h)?.name ?? h.id! }))}
          />}
          {!response && <div>{t('common.noData')}</div>}
        </div>
        <div className="py-5 border-b">
          <h3 className="text-xl">{t('stable.trainings.statistics.instructors')}</h3>
          <h4 className="text-gray-500 text-sm">{t('common.fromBeginning')}</h4>
          {response && <TrainingsPieChart
            values={_.map(response?.instructors, (v, k) => ({ id: k, value: v }))}
            titles={instructors?.map(i => ({ id: i.id!, label: instructorTranslation.getCurrentTranslation(i)?.name ?? i.id! }))}
          />}
          {!response && <div>{t('common.noData')}</div>}
        </div>
      </div>
    </div>
  )
}

const COLORS = [
  'fill-[--color-primary-500]',
  'fill-[--color-primary-500] opacity-80',
  'fill-[--color-primary-500] opacity-60',
  'fill-[--color-primary-500] opacity-40',
  'fill-[--color-primary-500] opacity-20',
];

interface TrainingsPieChartProps {
  values: { id: string, value: number }[];
  titles?: { id: string, label: string }[];
}

interface TrainingsLineChartProps {
  currentData: number[];
  lastData: number[];
}

const mergeData = (currentData: number[], lastData: number[], names: string[]) => {
  return names.map((name, i) => ({ name, current: currentData[i], last: lastData[i] }));
}

const TrainingsPieChart = (props: TrainingsPieChartProps) => {
  const { values, titles } = props;
  const data = values.sort((a, b) => b.value - a.value).map((v) => ({ name: titles ? titles.find(t => t.id === v.id)?.label ?? v.id : v.id, value: v.value }));



  return (
    <div style={{ height: 400 }}>
      <ResponsiveContainer>
        <PieChart data={data}>
          <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} className={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip position={{ x: 0, y: 0 }} />
          <Legend
            layout="vertical"
            align="left"
            verticalAlign="top"
            iconSize={0}
            formatter={(value, entry, index) => `${index + 1}. ${value} (${entry.payload?.value})`}
          />
        </PieChart>
      </ResponsiveContainer>
    </div >
  )
}

const TrainingsLineChartWeek = (props: TrainingsLineChartProps) => {
  const { currentData, lastData } = props;
  const { i18n, t } = useTranslation();
  const configurationState = useConfigurationState();

  const data = mergeData(
    currentData,
    lastData,
    _.range(0, 7).map(i => moment().startOf('isoWeek').add(i, 'days').toDate().toLocaleDateString(i18n.resolvedLanguage, { weekday: 'short' }))
  );

  return (
    <div style={{ height: 400 }}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <CartesianGrid stroke="#ccc" />
          <Line type="monotone"
            dataKey="last"
            stroke="grey"
            opacity={0.5}
            name={t('common.statistics.lastPeriod')}
          />
          <Line type="monotone"
            dataKey="current"
            stroke={_.get(colors, configurationState.layout.primaryColor ?? "gray")}
            name={t('common.statistics.currentPeriod')}
          />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip position={{ x: 0, y: 0 }} />
        </LineChart>
      </ResponsiveContainer>
    </div >
  );
}

const TrainingsLineChartMonth = (props: TrainingsLineChartProps) => {
  const { currentData, lastData } = props;
  const { t } = useTranslation();
  const configurationState = useConfigurationState();

  const data = mergeData(
    currentData,
    lastData,
    _.range(1, 32).map(i => String(i))
  );

  return (
    <div style={{ height: 400 }}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <CartesianGrid stroke="#ccc" />
          <Line type="monotone"
            dataKey="last"
            stroke="grey"
            opacity={0.5}
            name={t('common.statistics.lastPeriod')}
          />
          <Line type="monotone"
            dataKey="current"
            stroke={_.get(colors, configurationState.layout.primaryColor ?? "gray")}
            name={t('common.statistics.currentPeriod')}
          />
          <XAxis dataKey="name" ticks={["1", "5", "10", "15", "20", "25", "30"]} />
          <YAxis />
          <Tooltip position={{ x: 0, y: 0 }} />
        </LineChart>
      </ResponsiveContainer>
    </div >
  );
}

const TrainingsLineChartWeekByWeek = (props: TrainingsLineChartProps) => {
  const { currentData, lastData } = props;
  const { t } = useTranslation();
  const configurationState = useConfigurationState();

  const data = mergeData(
    currentData,
    lastData,
    _.range(1, 53).map(i => String(i))
  );

  return (
    <div style={{ height: 400 }}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <CartesianGrid stroke="#ccc" />
          <Line type="monotone"
            dataKey="last"
            stroke="grey"
            opacity={0.5}
            name={t('common.statistics.lastPeriod')}
          />
          <Line type="monotone"
            dataKey="current"
            stroke={_.get(colors, configurationState.layout.primaryColor ?? "gray")}
            name={t('common.statistics.currentPeriod')}
          />
          <XAxis dataKey="name" ticks={["1", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50"]} />
          <YAxis />
          <Tooltip position={{ x: 0, y: 0 }} />
        </LineChart>
      </ResponsiveContainer>
    </div >
  );
}

const TrainingsLineChartMonthByMonth = (props: TrainingsLineChartProps) => {
  const { currentData, lastData } = props;
  const { i18n, t } = useTranslation();
  const configurationState = useConfigurationState();

  const data = mergeData(
    currentData,
    lastData,
    _.range(0, 12).map(i => moment().startOf('year').add(i, 'months').toDate().toLocaleDateString(i18n.resolvedLanguage, { month: 'short' }))
  );

  return (
    <div style={{ height: 400 }}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <CartesianGrid stroke="#ccc" />
          <Line type="monotone"
            dataKey="last"
            stroke="grey"
            opacity={0.5}
            name={t('common.statistics.lastPeriod')}
          />
          <Line type="monotone"
            dataKey="current"
            stroke={_.get(colors, configurationState.layout.primaryColor ?? "gray")}
            name={t('common.statistics.currentPeriod')}
          />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip position={{ x: 0, y: 0 }} />
        </LineChart>
      </ResponsiveContainer >
    </div >
  );
}

const colors = {
  "slate": "#6b7280",
  "gray": "#6b7280",
  "zinc": "#71717a",
  "neutral": "#737373",
  "stone": "#78716c",
  "red": "#ef4444",
  "orange": "#f97316",
  "amber": "#f59e0b",
  "yellow": "#eab308",
  "lime": "#84cc16",
  "green": "#22c55e",
  "emerald": "#10b981",
  "teal": "#14b8a6",
  "cyan": "#06b6d4",
  "sky": "#0ea5e9",
  "blue": "#3b82f6",
  "indigo": "#6366f1",
  "violet": "#8b5cf6",
  "purple": "#a855f7",
  "fuchsia": "#d946ef",
  "pink": "#ec4899",
  "rose": "#f43f5e",
  "sand": "#c8a250",
  "cactus": "#377870",
  "sun": "#ffcc1e",
  "cornflower": "#0967D2",
  "lily": "#DA127D",
  "paradise": "#1ab3e1",
};