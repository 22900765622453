import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Settings, SettingsClient } from "src/api/core/Core";
import Button from "src/components/Actions/Button";
import BookingWorkingHours, { daysOfWeek } from "src/components/Booking/BookingWorkingHours";
import Alert from "src/components/Feedback/Alert";
import Toast from "src/components/Feedback/Toast";
import Select from "src/components/Form/FormSelect";
import FormSwitch from "src/components/Form/FormSwitch";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useConfigurationDispatch from "src/hooks/useConfigurationDispatch";
import useConfigurationState from "src/hooks/useConfigurationState";

import { setConfiguration } from "src/store/configuration/actions";
import { AuthorizationScope } from "src/store/configuration/state";

const BookingSettingsSection = () => {
  const { t } = useTranslation();
  const configurationDispatch = useConfigurationDispatch();
  const configurationState = useConfigurationState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new SettingsClient(apiConfiguration);

  const onSaveSuccess = () => {
    Toast.success(t("common.status.success"), t("common.feedback.saved"));
  }

  const onSaveFailure = () => {
    setError(true);
    Toast.error(t("common.status.error"), t("common.errors.500"));
  }

  const onSave = () => {
    setLoading(true);
    setError(false);
    apiClient.set('booking', undefined, { name: 'booking', value: configurationState.booking } as Settings)
      .catch(onSaveFailure)
      .then(onSaveSuccess)
      .finally(() => setLoading(false));
  }

  const authorizationScope = [
    { id: String(AuthorizationScope.Any), value: AuthorizationScope.Any, label: t('accessScope.any'), disabled: !configurationState.booking.guests },
    { id: String(AuthorizationScope.Users), value: AuthorizationScope.Users, label: t('accessScope.users') },
    { id: String(AuthorizationScope.Noone), value: AuthorizationScope.Noone, label: t('accessScope.noone') },
  ];

  return (
    <>
      {error && <Alert.Error title={t('common.errors.500')} noClose />}
      <dl className="my-6 space-y-6 divide-y divide-gray-50 border-y border-gray-100 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6"></dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={configurationState.booking.guests}
                onChange={(e) => configurationDispatch(setConfiguration('booking.guests', e.target.checked))}
                placeholder={t('panel.settings.booking.guests.title')}
                aria-description={t('panel.settings.booking.guests.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6"></dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={configurationState.booking.requireEquestrianCentreAgreements}
                onChange={(e) => configurationDispatch(setConfiguration('booking.requireEquestrianCentreAgreements', e.target.checked))}
                placeholder={t('panel.settings.booking.requireEquestrianCentreAgreements.title')}
                aria-description={t('panel.settings.booking.requireEquestrianCentreAgreements.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6"></dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={configurationState.booking.requirePhoneNumber}
                onChange={(e) => configurationDispatch(setConfiguration('booking.requirePhoneNumber', e.target.checked))}
                placeholder={t('panel.settings.booking.requirePhoneNumber.title')}
                aria-description={t('panel.settings.booking.requirePhoneNumber.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.granularity.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <Select
                name="trainingType"
                options={[
                  { id: '30', value: '30', label: t('panel.settings.booking.granularity.30') },
                  { id: '60', value: '60', label: t('panel.settings.booking.granularity.60') },
                ]}
                value={configurationState.booking.granularity ?? 30}
                placeholder={t('panel.settings.booking.granularity.title')}
                aria-description={t('panel.settings.booking.granularity.description')}
                onChange={(e) => configurationDispatch(setConfiguration('booking.granularity', Number(e.target.value)))}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.trainingTypeSelection.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <Select
                name="trainingType"
                options={authorizationScope}
                value={configurationState.booking.selectTrainingType}
                placeholder={t('panel.settings.booking.trainingTypeSelection.title')}
                aria-description={t('panel.settings.booking.trainingTypeSelection.description')}
                onChange={(e) => configurationDispatch(setConfiguration('booking.selectTrainingType', e.target.value))}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.instructorSelection.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <Select
                name="instructor"
                options={authorizationScope}
                value={configurationState.booking.selectInstructor}
                placeholder={t('panel.settings.booking.instructorSelection.title')}
                aria-description={t('panel.settings.booking.instructorSelection.description')}
                onChange={(e) => configurationDispatch(setConfiguration('booking.selectInstructor', e.target.value))}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.horseSelection.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <Select
                name="horse"
                options={authorizationScope}
                value={configurationState.booking.selectHorse}
                placeholder={t('panel.settings.booking.horseSelection.title')}
                aria-description={t('panel.settings.booking.horseSelection.description')}
                onChange={(e) => configurationDispatch(setConfiguration('booking.selectHorse', e.target.value))}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.workingHours.title')}</dt>
          <dd className="mt-1 mb-6 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <table className="max-w-full">
              {daysOfWeek
                .map((d, i) => <BookingWorkingHours
                  key={i}
                  dayOfWeek={d}
                  value={_.get(configurationState.booking.hours, d)}
                  onChange={(value) => configurationDispatch(setConfiguration(`booking.hours.${d}`, value))}
                />)}
            </table>
          </dd>
        </div>
      </dl>
      <div className="flex justify-end">
        <Button colorName="primary" onClick={onSave} disabled={loading}><FontAwesomeIcon icon={faSave} className="h-5" /> {t('common.actions.save')}</Button>
      </div>
    </>
  );
}

export default BookingSettingsSection;