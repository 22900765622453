import { FC, useEffect, useRef } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { HorseTask, HorseTasksClient, PeriodicBookingDeleteRequest, PeriodicUpdateDecision } from 'src/api/stable/Booking';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import PeriodicOperationModal, { PeriodicOperation, PeriodicOperationModalRef } from 'src/components/Booking/PeriodicOperationModal';
import moment from 'moment';

const HorseTasksForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const apiClient = new HorseTasksClient(apiConfiguration);
  const form = useForm({ source: 3 } as HorseTask);
  const authGuard = useAuthGuard('/panel/', ['TasksRead']);
  const TasksUpdateClaim = useClaim('TasksUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();
  const updateModal = useRef<PeriodicOperationModalRef>(null);
  const deleteModal = useRef<PeriodicOperationModalRef>(null);

  const onDelete = () => {
    if (form.data.periodic) {
      deleteModal.current?.open();
      return;
    }
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id, {} as PeriodicBookingDeleteRequest)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/stable/workers/tasks/`)
      })
  }

  const onSubmit = () => {
    if (form.data.id && form.data.periodic && (!form.data.periodicUpdateDate || !form.data.periodicUpdateDecision)) {
      updateModal.current?.open();
      form.setPending(false);
      return;
    }
    form.setPending(true);
    if (!form.data || !id) return;
    if (id !== 'create') {
      apiClient.update(id, form.data)
        .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      apiClient.create(form.data)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          navigate(`/panel/stable/horses/tasks/`);
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const fetch = () => {
    if (!id) return;
    form.setPending(true);
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "stable.horses.group", href: "/panel/stable/horses/" },
        { label: "stable.tasks.group", href: "/panel/stable/horses/tasks/" },
        { label: id !== 'create' ? "Edit horse task" : "New horse task", href: `/panel/stable/horses/tasks/${id}` }
      ])
    );
  }, [id]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    let data = { ...form.data } as HorseTask;
    if (query.get('date')) {
      data = {
        ...data,
        start: new Date(query.get('date')!),
        end: moment(new Date(query.get('date')!)).add(1, 'hour').toDate()
      } as HorseTask;
    }
    if (query.get('userId')) data.userId = query.get('userId')!;
    if (query.get('horseId')) data.horseId = query.get('horseId')!;
    form.setData(data);
  }, [window.location.search]);

  useEffect(() => {
    form.setReadOnly(!TasksUpdateClaim);
  }, [TasksUpdateClaim])

  if (authGuard === undefined) return <AuthGuardLoading />

  const onConfirmPeriodicUpdate = (decision: PeriodicUpdateDecision, date: Date) => {
    form.setPending(true);
    apiClient.update(id!, { ...form.data, periodicUpdateDate: date, periodicUpdateDecision: decision } as HorseTask)
      .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
      .catch(e => form.catchAnyException(e, true))
      .finally(() => form.setPending(false));
  }

  const onConfirmPeriodicDelete = (decision: PeriodicUpdateDecision, date: Date) => {
    form.setPending(true);
    apiClient.delete(id!, { periodicUpdateDate: date, periodicUpdateDecision: decision } as PeriodicBookingDeleteRequest)
      .then(() => {
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/stable/workers/tasks/`);
      })
      .catch(e => form.catchAnyException(e, true))
      .finally(() => form.setPending(false));
  }

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit)}>
        <Header horseTask={form.data} onDelete={onDelete} form={form} />
        <GeneralForm form={form} />
        <div className="text-end mt-5">
          <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
            {t('common.actions.save')}
          </Button>
        </div>
      </form >
      <PeriodicOperationModal ref={updateModal} onConfirm={onConfirmPeriodicUpdate} operation={PeriodicOperation.Update} />
      <PeriodicOperationModal ref={deleteModal} onConfirm={onConfirmPeriodicDelete} operation={PeriodicOperation.Delete} />
    </>
  )
}

export default HorseTasksForm;