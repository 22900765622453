import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import { IHttpQueryFilter } from 'src/api/Interfaces';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import FormInput from 'src/components/Form/FormInput';
import { IForm } from 'src/hooks/useForm';
import TableFilters from 'src/components/Data/TableFilters';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { Content, ContentTranslation, ClientPagesClient, HttpQueryFilter } from 'src/api/cms/Cms';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faEdit, faLock, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { QueryOrderDirection } from 'src/api/Base';

interface PageFilters {
  url?: string;
  title?: string;
  updatedFrom?: string;
  updatedTo?: string;
}

function prepareFilters(form: IForm<PageFilters>): IHttpQueryFilter[] {
  const f: IHttpQueryFilter[] = [];
  if (form.data.url) f.push({ property: 'translations.url', value: form.data.url, type: '%' });
  if (form.data.title) f.push({ property: 'translations.title', value: form.data.title, type: '%' });
  return f;
}

function renderFiltersForm(form: IForm<PageFilters>) {
  const { t } = useTranslation();
  return (
    <>
      <form onReset={form.onReset}>
        <FormInput.Default {...form.input("url", "text", { placeholder: t('common.fields.url') })} />
        <FormInput.Default {...form.input("title", "text", { placeholder: t('common.fields.title') })} />
        <div className="text-end mt-5">
          <Button colorName="red" type="reset">
            {t('common.actions.reset')}
          </Button>
        </div>
      </form>
    </>
  );
}

interface PagesTableProps {
  filters?: HttpQueryFilter[];
  noHeader?: boolean;
  noActions?: boolean;
}

const PagesTable = (props: PagesTableProps) => {
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientPagesClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<Content>>();
  const contentsUpdateClaim = useClaim('PagesUpdate');
  const contentsDeleteClaim = useClaim('PagesDelete');
  const authGuard = useAuthGuard('/panel/', ['PagesRead']);
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const [filters, setFilters] = useState<IHttpQueryFilter[]>([])
  const applicationDispatch = useApplicationDispatch();

  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastPage() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Content)}/hubs/PagesHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/

  const onClickDelete = (entity: Content) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const columns: ITableColumn<Content>[] = [
    { label: t('common.fields.url'), selector: row => entityTranslation.getCurrentTranslation(row)?.url, id: "translations.url" },
    { label: t('common.fields.title'), selector: row => entityTranslation.getCurrentTranslation(row)?.title, id: "translations.title" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleDateString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="flex justify-end gap-x-3">
        {contentsUpdateClaim &&
          <LocalizedLink to={`/panel/content/pages/pages/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {row.isLocked && <FontAwesomeIcon icon={faLock} className="w-5" />}
        {contentsDeleteClaim && !row.isLocked &&
          <Button colorName="red" onClick={() => onClickDelete(row)}>
            <FontAwesomeIcon icon={faTimes} className="w-5" />
          </Button>}
      </div >
    }
  ];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "cms.pages.pages.group", href: "/panel/content/pages/pages/" }
      ])
    );
  }, [])

  useEffect(() => {
    tableRef.current?.setFilters([
      ...filters,
      ...props.filters || [],
      { property: 'id', value: 'b7cdaccb-08cc-49f8-82ad-739e1f738378', type: '!=' },
      { property: 'id', value: '3b9f58a4-2682-4fe3-b395-6313e8d1cf56', type: '!=' },
    ]);
  }, [filters]);

  useEffect(() => {
    tableRef.current?.setFilters([
      ...props.filters || [],
      { property: 'id', value: 'b7cdaccb-08cc-49f8-82ad-739e1f738378', type: '!=' },
      { property: 'id', value: '3b9f58a4-2682-4fe3-b395-6313e8d1cf56', type: '!=' },
    ]);
  })

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <div className="flex justify-between">
        {!props.noHeader && <div className="flex gap-x-3">
          <h1 className="leading-1 text-2xl">{t('cms.pages.pages.group')}</h1>
        </div>}
        {!props.noActions && <div className="flex gap-x-3">
          {contentsUpdateClaim &&
            <LocalizedLink to="/panel/content/pages/pages/create">
              <Button colorName="emerald" className="px-5 py-3 text-md">
                <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
                {t('cms.pages.pages.actions.create')}
              </Button>
            </LocalizedLink>}
          <TableFilters
            filters={filters}
            setFilters={setFilters}
            formRender={renderFiltersForm}
            prepareFilters={prepareFilters}
          />
        </div>}
      </div >
      <Table<Content>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        defaultOrder={{ property: 'created', direction: QueryOrderDirection.DESC }}
        filters={filters}
      />
    </>
  )
}

export default PagesTable;