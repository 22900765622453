import { createRef, FC, useEffect } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { User, UsersClient } from 'src/api/access/Authority';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import PasswordForm from './Form/Password';
import TwoFactorForm from './Form/TwoFactor';
import SingleSignOnForm from './Form/SingleSignOn';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import RolesForm from './Form/RolesForm';
import { Dictionary } from 'lodash';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import { ConfigurationApis, getApiBaseUrl } from 'src/config/config';
import useTenant from 'src/hooks/useTenant';
import { useUploader } from 'src/hooks/useUploader';
import { FormImageComponentRef } from 'src/components/Form/FormImage';

export interface UserForm extends User {
  repeatPassword?: string;
}

const UsersForm: FC = () => {
  const apiConfiguration = useApiConfiguration();
  const { t, i18n } = useTranslation();

  const apiClient = new UsersClient(apiConfiguration);
  const form = useForm({ culture: i18n.resolvedLanguage, source: 3 } as UserForm);
  const authGuard = useAuthGuard('/panel/', ['UsersRead']);
  const usersUpdateClaim = useClaim('UsersUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const applicationDispatch = useApplicationDispatch();
  const tenant = useTenant();

  const photoUrl = `${getApiBaseUrl(ConfigurationApis.Auth)}/api/v1/avatars/${id}?XTenantId=${tenant}&${(new Date()).getTime()}`;
  const uploadAvatar = (file: File) => new Promise<string>(
    (resolve, reject) => {
      const onAvatarUploadError = (e: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
        console.error(e);
        reject();
      }
      const onAvatarUploadSuccess = () => {
        const url = `${photoUrl}&${(new Date()).getTime()}`;
        resolve(url);
        if (id === 'create') {
          navigate(`/panel/users/`);
        }
      }

      apiClient
        .uploadAvatar(form.data.id!, undefined, { fileName: file.name, data: file })
        .catch(onAvatarUploadError)
        .then(onAvatarUploadSuccess);
    }
  );
  const uploader = useUploader(uploadAvatar);
  const uploaderRef = createRef<FormImageComponentRef>();

  const onDelete = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/users/`)
      })
  }

  const onSubmit = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id !== 'create') {
      apiClient.update(id, form.data)
        .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      apiClient.create(form.data)
        .then((response) => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          form.setData({ ...response } as User);
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const validate = (): Dictionary<string[]> => {
    const result = {} as Dictionary<string[]>;
    if (form.data.password || form.data.repeatPassword) {
      if (form.data.password !== form.data.repeatPassword) {
        result["repeatPassword"] = ["ShouldBeTheSame"];
      }
    }
    return result;
  }

  const fetch = () => {
    if (!id) return;
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException);
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "users.group", href: "/panel/users/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/users/${id}` }
      ])
    );
    if (id !== 'create') uploader?.setPhoto(photoUrl);
  }, [id]);

  useEffect(() => {
    if (id === 'create' && form.data.id !== undefined) {
      if (uploaderRef.current?.file) {
        uploaderRef.current?.upload();
      } else {
        navigate(`/panel/users/`);
      }
    }
  }, [form.data?.id])

  useEffect(() => {
    form.setReadOnly(!usersUpdateClaim);
  }, [usersUpdateClaim])

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
        <Header user={form.data} onDelete={onDelete} form={form} />
        <GeneralForm form={form} uploader={uploader} uploaderRef={uploaderRef} />
        <PasswordForm form={form} />
        <RolesForm form={form} />
        <TwoFactorForm form={form} />
        <SingleSignOnForm form={form} />
        <div className="text-end mt-5">
          <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
            {t('common.actions.save')}
          </Button>
        </div>
      </form >
    </>
  )
}

export default UsersForm;