import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownRight, faArrowRight, faArrowUpRight } from "@fortawesome/pro-duotone-svg-icons";

interface GrowIconProps {
  previousValue?: number;
  currentValue?: number;
  className?: string;
}

export default (props: GrowIconProps) => {
  const { previousValue, currentValue, className } = props;

  const value = (currentValue ?? 0) - (previousValue ?? 0);

  if (value > 0) {
    return <FontAwesomeIcon icon={faArrowUpRight} className={`ml-1 text-emerald-700 ${className}`} />;
  }
  if (value < 0) {
    return <FontAwesomeIcon icon={faArrowDownRight} className={`ml-1 text-rose-700 ${className}`} />;
  }
  return <FontAwesomeIcon icon={faArrowRight} className={`ml-1 text-gray-500 ${className}`} />;
}