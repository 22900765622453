import { faChartArea, faTable } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Actions/Button";
import { useLocation } from "react-router";
import { useEffect } from "react";

export enum ListView {
  Table,
  Statistics
}

export interface StatisticsSwitchProps {
  view?: ListView;
  setView: (view: ListView) => void;
}

export default (props: StatisticsSwitchProps) => {
  const { view, setView } = props;
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === '#statistics') {
      setView(ListView.Statistics);
      return;
    }
    else if (hash === '#table') {
      setView(ListView.Table);
      return;
    }
    setView(ListView.Table);
  }, [hash]);

  useEffect(() => {
    if (view === ListView.Statistics) {
      window.location.hash = '#statistics';
      return;
    }
    else if (view === ListView.Table) {
      window.location.hash = '#table';
      return;
    }
  }, [view]);

  return (
    <>
      {view === ListView.Table && (<Button onClick={() => setView(ListView.Statistics)}>
        <FontAwesomeIcon icon={faChartArea} className="w-5" />
      </Button>)}
      {view === ListView.Statistics && (<Button onClick={() => setView(ListView.Table)}>
        <FontAwesomeIcon icon={faTable} className="w-5" />
      </Button>)}
    </>
  )
}