import { useTranslation } from "react-i18next";
import Button from "../Actions/Button"


export enum StatisticsPeriod {
  Week = 1,
  Month = 2,
  Year = 3
}

export interface StatisticsPeriodSwitchProps {
  period: StatisticsPeriod
  setPeriod: (period: StatisticsPeriod) => void
}

export default (props: StatisticsPeriodSwitchProps) => {
  const { period, setPeriod } = props;
  const { t } = useTranslation();
  return (
    <div className="flex">
      <Button
        className="rounded-l"
        colorName={period === StatisticsPeriod.Week ? "primary" : undefined}
        onClick={() => setPeriod(StatisticsPeriod.Week)}
      >
        {t('common.period.thisWeek')}
      </Button>
      <Button
        className="rounded-none"
        colorName={period === StatisticsPeriod.Month ? "primary" : undefined}
        onClick={() => setPeriod(StatisticsPeriod.Month)}
      >
        {t('common.period.thisMonth')}
      </Button>
      <Button
        className="rounded-r"
        colorName={period === StatisticsPeriod.Year ? "primary" : undefined}
        onClick={() => setPeriod(StatisticsPeriod.Year)}
      >
        {t('common.period.thisYear')}
      </Button>
    </div>
  )
}