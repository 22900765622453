import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { Horse, HorseTranslation, HorsesClient } from 'src/api/stable/Stable';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import WeekHeatmap from 'src/components/Statistics/WeekHeatmap';
import StatisticsPeriodSwitch, { StatisticsPeriod } from 'src/components/Statistics/StatisticsPeriodSwitch';
import { HttpQueryFilter, HttpQueryOrder, Int32WeeklyHeatMapStatistics, QueryOrderDirection, TrainingsClient, TrainingsStatisticsClient, TrainingStatistics } from 'src/api/stable/Booking';
import { weeklyHeatMapResult } from 'src/components/Statistics/weeklyHeatMapResult';
import HorseSelect from 'src/components/Stable/HorseSelect';
import useConfigurationState from 'src/hooks/useConfigurationState';
import _ from 'lodash';
import { ResponsiveContainer, LineChart, CartesianGrid, Line, XAxis, YAxis, Tooltip } from 'recharts';
import Stat, { StatData } from 'src/components/Statistics/Stat';
import moment from 'moment';
import GrowTag from 'src/components/Statistics/GrowTag';

export default () => {
  const { t } = useTranslation();
  const [horseId, setHorseId] = useState<string>();
  const [horses, setHorses] = useState<Horse[]>();

  const apiConfiguration = useApiConfiguration();
  const apiHorsesClient = new HorsesClient(apiConfiguration);

  const currentHorse = useMemo(() => horses?.find(h => h.id === horseId), [horses, horseId]);

  useEffect(() => {
    fetchHorses();
  }, []);

  const fetchHorses = () => {
    apiHorsesClient
      .get(undefined, undefined, 1000, 0, undefined, undefined)
      .then(response => setHorses(response.items))
      .catch(console.error);
  }

  return (
    <>
      <div className="mb-8 flex flex-col gap-y-5">
        <div className="max-w-full w-64">
          <HorseSelect onChange={(i) => setHorseId(i as string)} value={horseId} placeholder={t('stable.horses.item')} />
        </div>
        <div className="text-gray-500">{t('stable.horses.statistics.selectToViewDetails')}</div>
      </div>
      {currentHorse ? <HorseStatistics horse={currentHorse} /> : <OverallStatistics horses={horses} />}
    </>
  )
};

export const OverallStatistics = (props: { horses?: Horse[] }) => {
  const { horses } = props;
  const { t } = useTranslation();
  const [statisticsPeriod, setStatisticsPeriod] = useState<StatisticsPeriod>(StatisticsPeriod.Week);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new TrainingsStatisticsClient(apiConfiguration);
  const [heatMapResponse, setHeatMapResponse] = useState<Int32WeeklyHeatMapStatistics>();
  const horseTranslation = useEntityTranslation<Horse, HorseTranslation>();

  useEffect(() => {
    setHeatMapResponse(undefined);
    fetchHeatMaps();
  }, [statisticsPeriod]);

  const fetchHeatMaps = () => {
    apiClient
      .heatMapHorses(statisticsPeriod, [])
      .then(r => setHeatMapResponse(r))
      .catch(console.error);
  }

  const { values, legendY } = weeklyHeatMapResult(heatMapResponse, horses?.map(h => h.id!) ?? []);

  return (
    <div className="gap-y-5 my-5">
      <div className="flex justify-between">
        <h3 className="text-xl">{t('stable.trainings.statistics.weeklyHeatmap')}</h3>
        <StatisticsPeriodSwitch period={statisticsPeriod} setPeriod={setStatisticsPeriod} />
      </div>
      <WeekHeatmap
        labelY="Koń"
        legendY={legendY}
        values={values}
        legendYFormatter={(id) => horseTranslation.getCurrentTranslation(horses?.find(h => h.id === id))?.name ?? id}
      />
    </div>
  )
}

export const HorseStatistics = (props: { horse: Horse }) => {
  const { horse } = props;
  const { t } = useTranslation();
  const [statisticsPeriod, setStatisticsPeriod] = useState<StatisticsPeriod>(StatisticsPeriod.Week);
  const [stats, setStats] = useState<StatData[]>([]);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new TrainingsStatisticsClient(apiConfiguration);
  const trainingsClient = new TrainingsClient(apiConfiguration);
  const [heatMapResponse, setHeatMapResponse] = useState<Int32WeeklyHeatMapStatistics>();
  const [statisticsResponse, setStatisticsResponse] = useState<TrainingStatistics>();

  useEffect(() => {
    setStatisticsResponse(undefined);
    setStats([]);
    fetchStatistics();
    fetchTrainings();
  }, [horse]);

  useEffect(() => {
    setHeatMapResponse(undefined);
    fetchHeatMaps();
  }, [horse, statisticsPeriod]);

  const fetchStatistics = () => {
    apiClient
      .statistics([{ property: 'horseId', type: '==', value: horse.id } as HttpQueryFilter])
      .then(r => setStatisticsResponse(r))
      .catch(console.error);
  }

  const fetchTrainings = () => {
    trainingsClient
      .get([
        { property: 'horseId', type: '=', value: horse.id },
        { property: 'isConfirmed', type: '!=', value: false },
      ] as HttpQueryFilter[], [
        { property: 'start', direction: QueryOrderDirection.DESC }
      ] as HttpQueryOrder[], 1000, undefined, undefined, undefined)
      .then(r => {
        const today = moment();
        const lastWeek = moment().subtract(1, 'week').week();
        const lastMonth = moment().subtract(1, 'month').month();

        const thisWeekTrainings = r.items?.filter(i => moment(i.start).isSame(today, 'isoWeek')) || [];
        const lastWeekTrainings = r.items?.filter(i => moment(i.start).week() === lastWeek) || [];
        const thisMonthTrainings = r.items?.filter(i => moment(i.start).month() === today.month()) || [];
        const lastMonthTrainings = r.items?.filter(i => moment(i.start).month() === lastMonth) || [];

        setStats([
          {
            name: t('stable.trainings.statistics.thisWeek'),
            value: Math.round(_.sum(thisWeekTrainings?.map(t => moment(t.end).diff(moment(t.start), 'minutes'))) / 6) / 10,
            formatter: (v) => `${v} h`
          },
          {
            name: t('stable.trainings.statistics.lastWeek'),
            value: Math.round(_.sum(lastWeekTrainings?.map(t => moment(t.end).diff(moment(t.start), 'minutes'))) / 6) / 10,
            formatter: (v) => `${v} h`
          },
          {
            name: t('stable.trainings.statistics.thisMonth'),
            value: Math.round(_.sum(thisMonthTrainings?.map(t => moment(t.end).diff(moment(t.start), 'minutes'))) / 6) / 10,
            formatter: (v) => `${v} h`
          },
          {
            name: t('stable.trainings.statistics.lastMonth'),
            value: Math.round(_.sum(lastMonthTrainings?.map(t => moment(t.end).diff(moment(t.start), 'minutes'))) / 6) / 10,
            formatter: (v) => `${v} h`
          }
        ]);
      })
      .catch(console.error);
  }

  const fetchHeatMaps = () => {
    apiClient
      .heatMapTrainings(statisticsPeriod, [{ property: 'horseId', type: '==', value: horse.id } as HttpQueryFilter])
      .then(r => setHeatMapResponse(r))
      .catch(console.error);
  }

  const { legendY, values } = weeklyHeatMapResult(heatMapResponse, [
    '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00',
    '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'
  ])

  return (
    <div className="flex flex-col gap-y-5 my-5">
      <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
        <dl className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
          {stats?.map((stat, i) => <Stat key={i} stat={stat} id={i} />)}
        </dl>
      </div>
      {statisticsResponse && <div>
        <h2 className="text-xl pb-5">
          {t('stable.trainings.statistics.thisMonth')}
          <GrowTag currentValue={_.sum(statisticsResponse.durationThisMonth)} previousValue={_.sum(statisticsResponse.durationLastMonth)} percentage />
        </h2>
        <TrainingsLineChartMonth currentData={statisticsResponse.durationThisMonth!} lastData={statisticsResponse.durationLastMonth!} />
      </div>}
      <div className="flex justify-between">
        <h3 className="text-xl">{t('stable.trainings.statistics.weeklyHeatmap')}</h3>
        <StatisticsPeriodSwitch period={statisticsPeriod} setPeriod={setStatisticsPeriod} />
      </div>
      <WeekHeatmap
        labelY={t('stable.horses.item')}
        legendY={legendY}
        values={values}
      />
    </div>
  )
}

interface TrainingsLineChartProps {
  currentData: number[];
  lastData: number[];
}

const TrainingsLineChartMonth = (props: TrainingsLineChartProps) => {
  const { currentData, lastData } = props;
  const { t } = useTranslation();
  const configurationState = useConfigurationState();

  const data = mergeData(
    currentData.map(i => Math.round(i / 6) / 10),
    lastData.map(i => Math.round(i / 6) / 10),
    _.range(1, 32).map(i => String(i))
  );

  return (
    <div style={{ height: 400 }}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <CartesianGrid stroke="#ccc" />
          <Line type="monotone"
            dataKey="last"
            stroke="grey"
            opacity={0.5}
            name={t('common.statistics.lastPeriod')}
            unit="h"
          />
          <Line type="monotone"
            dataKey="current"
            stroke={_.get(colors, configurationState.layout.primaryColor ?? "gray")}
            name={t('common.statistics.currentPeriod')}
            unit="h"
          />
          <XAxis dataKey="name" interval={3} />
          <YAxis unit="h" interval={1} />
          <Tooltip position={{ x: 0, y: 0 }} />
        </LineChart>
      </ResponsiveContainer>
    </div >
  );
}

const mergeData = (currentData: number[], lastData: number[], names: string[]) => {
  return names.map((name, i) => ({ name, current: currentData[i], last: lastData[i] }));
}

const colors = {
  "slate": "#6b7280",
  "gray": "#6b7280",
  "zinc": "#71717a",
  "neutral": "#737373",
  "stone": "#78716c",
  "red": "#ef4444",
  "orange": "#f97316",
  "amber": "#f59e0b",
  "yellow": "#eab308",
  "lime": "#84cc16",
  "green": "#22c55e",
  "emerald": "#10b981",
  "teal": "#14b8a6",
  "cyan": "#06b6d4",
  "sky": "#0ea5e9",
  "blue": "#3b82f6",
  "indigo": "#6366f1",
  "violet": "#8b5cf6",
  "purple": "#a855f7",
  "fuchsia": "#d946ef",
  "pink": "#ec4899",
  "rose": "#f43f5e",
  "sand": "#c8a250",
  "cactus": "#377870",
  "sun": "#ffcc1e",
  "cornflower": "#0967D2",
  "lily": "#DA127D",
  "paradise": "#1ab3e1",
};