import _ from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../Feedback/Spinner";

export interface WeekHeatmap {
  labelY?: string;
  legendY: string[];
  legendYFormatter?: (value: string) => string;
  values?: number[][];
}

export default (props: WeekHeatmap) => {
  const { labelY, legendY, values, legendYFormatter } = props;
  const { i18n } = useTranslation();
  const legendX = useMemo(() => {
    return _.range(0, 7).map(i => moment().startOf('isoWeek').add(i, 'days').toDate())
  }, [i18n.resolvedLanguage]);
  const noOfSteps = 10;

  if (!values || values.length === 0) {
    return <div className="flex justify-center"><Spinner /></div>;
  }

  const min = values.flat().reduce((a, b) => Math.min(a, b));
  const max = values.flat().reduce((a, b) => Math.max(a, b));
  const steps = min !== max ? _.range(min, max, (max - min) / noOfSteps) : [min];
  const colors = [
    ['bg-sky-0', '', 'text-gray-400'],
    ['bg-sky-50', '', 'text-sky-600'],
    ['bg-sky-50', 'opacity-10 bg-[--color-primary-500]', 'text-sky-700'],
    ['bg-sky-100', 'opacity-20 bg-[--color-primary-500]', 'text-sky-800'],
    ['bg-sky-100', 'opacity-30 bg-[--color-primary-500]', 'text-sky-700'],
    ['bg-sky-200', 'opacity-40 bg-[--color-primary-500]', 'text-sky-700'],
    ['bg-sky-200', 'opacity-50 bg-[--color-primary-500]', 'text-sky-800'],
    ['opacity-60 bg-[--color-primary-500]', '', 'text-white'],
    ['opacity-80 bg-[--color-primary-500]', '', 'text-white'],
    ['opacity-100 bg-[--color-primary-500]', '', 'text-white'],
  ];

  const getStep = (value: number) => {
    for (let i = 0; i < steps.length; i++) {
      if (value <= steps[i]) return i;
    }
    return steps.length - 1;
  }

  return (
    <div>
      <div className="grid grid-cols-8 my-3">
        <div className="text-gray-700 px-5 py-2 text-center border-b border-r">{labelY}</div>
        {legendX.map((x, i) => <div key={i} className="text-gray-700 px-5 py-2 text-center border-b">
          <span className="hidden md:inline">{x.toLocaleDateString(i18n.resolvedLanguage, { weekday: 'long' })}</span>
          <span className="inline md:hidden">{x.toLocaleDateString(i18n.resolvedLanguage, { weekday: 'short' })}</span>
        </div>)}
        {legendY.map((y, i) => {
          const valuesOfThisRow = legendX.map((x, j) => values[i][j]);
          const thisRowLegend = legendYFormatter ? legendYFormatter(legendY[i]) : legendY[i];
          return (
            <>
              <div className="text-gray-700 px-5 py-2 text-center border-r">{thisRowLegend}</div>
              {legendX.map((x, j) => {
                const value = valuesOfThisRow[j];
                const step = getStep(value);
                return (<div key={j} className="relative">
                  <div className={`absolute inset-0 size-full ${colors[step][0]}`}></div>
                  <div className={`absolute inset-0 size-full ${colors[step][1]}`}></div>
                  <div className={`absolute size-full ${colors[step][2]} px-5 py-2 text-center`}>{value}</div>
                </div>);
              })}
            </>
          )
        })}
      </div>
    </div>
  );
}
