import { growPercentage, growValue } from "./growCalculation";
import { growColorText, growColorBackground } from "./growColor";

interface GrowDotProps {
  previousValue?: number;
  currentValue?: number;
  className?: string;
  background?: boolean;
  percentage?: boolean;
}

export default (props: GrowDotProps) => {
  const { previousValue, currentValue, background, percentage } = props;

  const text = percentage ? growPercentage(currentValue, previousValue) : growValue(currentValue, previousValue);

  return background ? (
    <span className={`ml-3 text-white ${growColorBackground(currentValue, previousValue)} px-1 text-lg`}>{text}</span>
  ) : (
    <span className={`ml-3 ${growColorText(currentValue, previousValue)} px-1 text-lg`}>{text}</span>
  );
}