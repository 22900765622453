import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import { IHttpQueryFilter } from 'src/api/Interfaces';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import FormInput from 'src/components/Form/FormInput';
import { IForm } from 'src/hooks/useForm';
import TableFilters from 'src/components/Data/TableFilters';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { Horse, HorseTask, HorseTasksClient, HorseTranslation, PeriodicBookingDeleteRequest, PeriodicUpdateDecision } from 'src/api/stable/Booking';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faEdit, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { HorseImage } from 'src/components/Stable/HorseImage';
import moment from 'moment';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import PeriodicOperationModal, { PeriodicOperation, PeriodicOperationModalRef } from 'src/components/Booking/PeriodicOperationModal';
import { DataSourceIcon } from 'src/components/Enums/DataSource';
import { QueryOrderDirection } from 'src/api/Base';

interface HorseTaskFilters {
  url?: string;
  title?: string;
  updatedFrom?: string;
  updatedTo?: string;
}

function prepareFilters(form: IForm<HorseTaskFilters>): IHttpQueryFilter[] {
  const f: IHttpQueryFilter[] = [];
  if (form.data.url) f.push({ property: 'translations.url', value: form.data.url, type: '%' });
  if (form.data.title) f.push({ property: 'translations.title', value: form.data.title, type: '%' });
  return f;
}

function renderFiltersForm(form: IForm<HorseTaskFilters>) {
  const { t } = useTranslation();
  return (
    <>
      <form onReset={form.onReset}>
        <FormInput.Default {...form.input("url", "text", { placeholder: t('common.fields.url') })} />
        <FormInput.Default {...form.input("title", "text", { placeholder: t('common.fields.title') })} />
        <div className="text-end mt-5">
          <Button colorName="red" type="reset">
            {t('common.actions.reset')}
          </Button>
        </div>
      </form>
    </>
  );
}

const HorseTasksTable = () => {
  const { t, i18n } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new HorseTasksClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<HorseTask>>();
  const updateClaim = useClaim('TasksUpdate');
  const deleteClaim = useClaim('TasksDelete');
  const authGuard = useAuthGuard('/panel/', ['TasksRead']);
  const [filters, setFilters] = useState<IHttpQueryFilter[]>([])
  const applicationDispatch = useApplicationDispatch();
  const horseTranslation = useEntityTranslation<Horse, HorseTranslation>();
  const [deletedId, setDeletedId] = useState<string | undefined>();
  const deleteModal = createRef<PeriodicOperationModalRef>();

  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastHorseTask() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Stable)}/hubs/TasksHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/


  const onClickDelete = (entity: HorseTask) => {
    if (entity?.id) {
      apiClient.find(entity.id)
        .then(response => {
          if (response.periodic) {
            setDeletedId(entity.id);
            deleteModal.current?.open();
            return;
          } else {
            apiClient.delete(response.id!, {} as PeriodicBookingDeleteRequest)
              .then(() => {
                Toast.success(t("common.status.success"), t("common.feedback.deleted"));
                tableRef.current?.refresh()
              })
              .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
          }
        });
    }
  }

  const onConfirmPeriodicDelete = (decision: PeriodicUpdateDecision, date: Date) => {
    apiClient.delete(deletedId!, { periodicUpdateDate: date, periodicUpdateDecision: decision } as PeriodicBookingDeleteRequest)
      .then(() => {
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        tableRef.current?.refresh()
      })
      .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
  }

  const columns: ITableColumn<HorseTask>[] = [
    { label: t('common.fields.source'), selector: row => <DataSourceIcon value={row.source} />, id: "source" },
    { label: '', selector: row => <HorseImage id={row.horseId!} className="h-8 w-8 rounded-full ring-1" />, id: "horse.id" },
    { label: t('stable.horses.item'), selector: row => horseTranslation.getCurrentTranslation(row.horse)?.name, id: "horse.translations.title" },
    { label: t('common.fields.title'), selector: row => row.title, id: "title" },
    { label: t('stable.trainings.fields.start'), selector: row => row.created?.toLocaleString(), isOrderable: true, id: "start" },
    { label: t('stable.trainings.fields.end'), selector: row => row.created?.toLocaleString(), isOrderable: true, id: "end" },
    { label: t('stable.trainingTypes.fields.duration'), selector: row => Intl.NumberFormat(i18n.resolvedLanguage, { style: "unit", unit: "minute" }).format(Math.ceil(moment.duration(moment(row.end).diff(moment(row.start))).asMinutes())), id: "id" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleDateString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="flex justify-end gap-x-3">
        {updateClaim &&
          <LocalizedLink to={`/panel/stable/horses/tasks/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {deleteClaim && row.id && <Button colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "stable.horseTasks.group", href: "/panel/stable/horses/tasks/" }
      ])
    );
  }, [])

  useEffect(() => {
    tableRef.current?.setFilters(filters);
  }, [filters]);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <div className="flex justify-between">
        <div className="pt-2">
          <h1 className="leading-1 text-2xl">{t('stable.horseTasks.group')}</h1>
        </div>
        <div className="flex gap-x-3">
          {updateClaim &&
            <LocalizedLink to="/panel/stable/horses/tasks/create">
              <Button colorName="emerald" className="px-5 py-3 text-md">
                <FontAwesomeIcon icon={faPlusCircle} className="w-5" />
                {t('stable.horseTasks.actions.create')}
              </Button>
            </LocalizedLink>}
          <TableFilters
            filters={filters}
            setFilters={setFilters}
            formRender={renderFiltersForm}
            prepareFilters={prepareFilters}
          />
        </div>
      </div >
      <Table<HorseTask>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        defaultOrder={{ property: 'created', direction: QueryOrderDirection.DESC }}
        filters={filters}
      />
      <PeriodicOperationModal ref={deleteModal} onConfirm={onConfirmPeriodicDelete} operation={PeriodicOperation.Delete} />
    </>
  )
}

export default HorseTasksTable;